/////////////////////////////
// == Body
/////////////////////////////

$body-color:                  $white;
$text-muted:                  $gray-500;

/////////////////////////////
// == Fonts
/////////////////////////////

// Family


$font-family-sans-serif:      'Kulim Park', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base:             $font-family-sans-serif;



// Weight

$font-weight-lighter:          lighter;
$font-weight-light:            300;
$font-weight-normal:           400;
$font-weight-bold:             700;
$font-weight-bolder:           bolder;



// Size

$font-size-base:              1rem;
$h6-font-size:                $font-size-base*1.125;



// Heading

$headings-font-weight:        900;
$headings-color:              $white;





/////////////////////////////
// == Border 
/////////////////////////////

$border-radius:               6px;
$border-color:                $gray-300;




/////////////////////////////
// == Spacing
/////////////////////////////

$spacer:                       1rem;



/////////////////////////////
// == HR
/////////////////////////////

$hr-margin-y:                 0;
$hr-border-color:             $gray-300;



/////////////////////////////
// == Box Shadows
/////////////////////////////

$box-shadow-sm:               0 .125rem .25rem rgba($primary, .075) !default;
$box-shadow:                  0 .3125rem 1.875rem rgba($black, 0.2) !default;
$box-shadow-lg:               0 1rem 3rem rgba($primary, .175) !default;



/////////////////////////////
// == Buttons
/////////////////////////////

$btn-font-weight:             $font-weight-bold;
$btn-border-radius:           2rem;
$btn-border-width:            0;
$input-btn-padding-y:         .657rem;
$input-btn-padding-x:         1.6rem;
$input-btn-border-width:      1px;



/////////////////////////////
// == Navbar
/////////////////////////////

$navbar-padding-y:                  $spacer;
$navbar-light-toggler-border-color: $white;
$navbar-dark-color:                 $white;
$navbar-toggler-border-radius:      $border-radius;

/////////////////////////////
// == Navbar
/////////////////////////////

$card-border-width:           0px;



/////////////////////////////
// == Preview
/////////////////////////////

$preview-padding:             0.2rem;
$preview-bg:                  rgba($purple,0.08);
$preview-color:               darken($purple, 30%);
$preview-heading-margin:      $spacer*2;



/////////////////////////////
// == Footer
/////////////////////////////




/////////////////////////////
// == Wizard
/////////////////////////////

$wizard-header-title-bg:                    $dark;
$wizard-header-title-border:                $border-color;
$wizard-step-color:                         $white;
$secondary-color:                           $primary;
$jet:                                       blue;
$custom-radio-checkbox-border-disabled:     $gray-600;
$custom-radio-checkbox-border:              $gray-200;
$cta-header-button-hover:                   null;
$go-back-button-hover:                      null;  



/////////////////////////////
// == Inputs
/////////////////////////////

$input-padding-x:                           1rem;
$input-color:                               $gray-900;